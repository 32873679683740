import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AOS from "aos";
import SpinMasterPrivacyPolicy from "./Components/SpinMasterPrivacyPolicy";
import "aos/dist/aos.css";
import "./App.css";
const Navbar = lazy(() => import("./Components/Navbar"))
const Home = lazy(()=> import('./Components/Home/Index'))
const About = lazy(()=> import('./Components/About/Index'))
const Services = lazy(()=> import('./Components/Services/Index'))
const Portfolio = lazy(()=> import('./Components/Portfolio/Index'))
const Contact = lazy(()=> import('./Components/ContactUs/Index'))
const ErrorPage = lazy(()=> import('./Components/Error/ErrorPage'))

const App = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const { pathname } = useLocation();
  const showNavbarAndFooter = !pathname.startsWith("/spin-master");
  const [homeEmail , setHomeEmail] = useState("")
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
    AOS.refresh();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // window.addEventListener("contextmenu", (e) => e.preventDefault());
  return (
    <div>
      {showNavbarAndFooter && (
       <Suspense><Navbar scrollPos={scrollPos} setScrollPos={setScrollPos} /></Suspense>
      )}
      <Routes>
        <Route path="/" element={<Suspense><Home setHomeEmail={setHomeEmail}/></Suspense>} />
        {/* <Route path="/blog" element={<Blog/>} /> */}
        <Route path="/about" element={<Suspense><About /></Suspense>} />
        <Route path="/services" element={<Suspense><Services /></Suspense>} />
        <Route path="/portfolio" element={<Suspense><Portfolio /></Suspense>} />
        <Route path="/contact" element={<Suspense><Contact homeEmail={homeEmail} setHomeEmail={setHomeEmail}/></Suspense>} />
        {/* <Route path="/blog-details/:id" element={<BlogDetails/>} /> */}
        <Route
          path="/spin-master/privacy-policy"
          element={<SpinMasterPrivacyPolicy />}
        />
        <Route path="*" element={<Suspense><ErrorPage /></Suspense>} />
      </Routes>
    </div>
  );
};

export default App;
